import BoldbaneSVG from './pictograms/points/BOLDBANE.svg';
import BordBaenkePicnicSVG from "./pictograms/points/BORDE-BÆNKE-PICNIC.svg";
import BaalpladsSVG from "./pictograms/points/BAALPLADS.svg";
import BlaaFlagStrandSVG from "./pictograms/points/BLAA-FLAG-STRAND.svg";
import BlaaFlagFacilitetSVG from "./pictograms/points/BLA-FLAG-FACILITET.svg";
import BaalhytteSVG from "./pictograms/points/BAALHYTTE.svg";
import BiografSVG from "./pictograms/points/BIOGRAF.svg";
import BaadpladsSVG from "./pictograms/points/BAADPLADS.svg";
import BadebroSVG from './pictograms/points/BADEBRO.svg';
import BadepunktSVG from './pictograms/points/BADEPUNKT.svg';

import Discgolfanlaeg from './pictograms/points/DISCGOLFANLAEG.svg'

import CampingpladsSVG from './pictograms/points/CAMPINGPLADS.svg';
import CykelpumpestationServicepostSVG from './pictograms/points/CYKELPUMPESTATION-SERVICE.svg';

import LegepladsSVG from './pictograms/points/LEGEPLADS.svg';

import ToiletSVG from "./pictograms/points/TOILET.svg";

import ShelterSVG from "./pictograms/points/SHELTER.svg";

import OrienteringsruteStandpunktSVG from "./pictograms/points/ORIENTERINGSRUTE_STARTPUNKT.svg";

import VandpostSVG from "./pictograms/points/VANDPOST.svg";

import TeltpladsSVG from "./pictograms/points/TELTPLADS.svg";
import IdraetsanlaegSVG from "./pictograms/points/IDRÆTANLÆG.svg";

import FeriecenterSVG from "./pictograms/points/FERIECENTER.svg";
import FugletaarnSVG from "./pictograms/points/FUGLETAARN-UDSIGTSTAARN.svg";
import FiskesoeSVG from "./pictograms/points/FISKESOE.svg";
import FormidlingAfFortidsminderSVG from "./pictograms/points/FORMIDLING-AF-FORTIDSMINDER.svg";
import FriluftsbadSvoemmebadSVG from "./pictograms/points/FRILUFTSBAD-SVOEMMEBAD.svg";
import FritFiskeriSVG from "./pictograms/points/FRIT-FISKERI.svg";
import FormidlingscenterSVG from "./pictograms/points/FORMIDLINGSCENTER.svg";
import FlugtskydningsbaneSVG from "./pictograms/points/FLUGTSKYDNINGSBANE.svg";
import FriTeltningSVG from "./pictograms/points/FRI TELTNING.svg";

import HistoriskeStederSVG from "./pictograms/points/HISTORISKE STEDER.svg";
import HoldepladsSVG from "./pictograms/points/HOLDEPLADS.svg";
import HundeskovHundeparkSVG from "./pictograms/points/HUNDESKOV-HUNDEPARK.svg";
import HytterOgLejrskolerSVG from "./pictograms/points/HYTTER-LEJRSKOLER.svg"

import IdraetshalSVG from "./pictograms/points/IDRAETSHAL.svg";
import InfoTavleSVG from "./pictograms/points/INFO-TAVLE.svg";

import KlatreanlaegSVG from './pictograms/points/KLATREANLAEG.svg';
import KunstSVG from "./pictograms/points/KUNST.svg"

import OphalerIsaetningspladsSVG from "./pictograms/points/OPHALER-ISAETNINGSPLADS.svg";
import UdstillingSVG from "./pictograms/points/UDSTILLING.svg"
import KanoKajakrastepladsSVG from "./pictograms/points/KANO-KAJAK RASTEPLADS.svg";
import UdendoersFitnessMotionspladsSVG from "./pictograms/points/UDENDOERSFITNESS-MOTIONSPLADS.svg";
import KetchersportsanlaegSVG from "./pictograms/points/KETSJERSPORTSANLAE.svg";

import ReakreativtNaturomraadeSVG from "./pictograms/points/REKREATIVTNATUROMRAADE.svg";

import CykelbaneSVG from "./pictograms/points/CYKELBANE.svg";

import RideanlaegSVG from "./pictograms/points/RIDEANLAEG.svg";

import NaturcenterSVG from "./pictograms/points/NATURCENTER.svg";
import NaturlegepladsSVG from "./pictograms/points/NATURLEGEPLADS.svg";

import AndetSVG from "./pictograms/points/ANDET.svg";

import AgilitybaneSVG from "./pictograms/points/AGILITYBANE.svg";

import FiskeplatformSVG from "./pictograms/points/FISKEPLATFORM.svg";

import ForeningshusSVG from "./pictograms/points/FORENINGSHUS.svg";
import ForsamlingshusSVG from "./pictograms/points/FORSAMLINGSHUS.svg";


import KitesurfOmraadeSVG from "./pictograms/points/KITESURFOMRÅDE.svg";
import KaelkebakkeSVG from "./pictograms/points/KAELKEBAKKE.svg";

import BadestrandStedSVG from "./pictograms/points/BADESTRAND-STED.svg";

import UdsigtspunktSVG from "./pictograms/points/UDSIGTSPUNKT.svg";

import ToervejrsrumMadpakkehusSVG from "./pictograms/points/TØRVEJRSRUM-MADPAKKEHUS.svg";
import TuristinformationSVG from "./pictograms/points/TURISTINFORMATION.svg";

import MinigolfbaneSVG from "./pictograms/points/MINIGOLFBANE.svg";
import MuseumSVG from "./pictograms/points/MUSEUM.svg";

import NaturbaseSVG from "./pictograms/points/NATURBASE.svg";
import NaturskoleSVG from "./pictograms/points/NATURSKOLE.svg";
import NaturkanonSVG from "./pictograms/points/NATURKANON.svg";

import ParkSVG from "./pictograms/points/PARK.svg";

import PentanquebaneSVG from "./pictograms/points/PENTANQUEBANE.svg";
import ParkeringspladsSVG from "./pictograms/points/PARKERINGSPLADS.svg";

import SpejderhytteSVG from "./pictograms/points/SPEJDERHYTTE.svg";
import SkojtebaneSVG from "./pictograms/points/SKOEJTEBANE.svg";
import SkydebaneanlaegSVG from "./pictograms/points/SKYDEBANEANLAEG.svg";
import SvoemmehalSVG from "./pictograms/points/SVOEMMEHAL.svg";
import SkateanlaegSVG from "./pictograms/points/SKATEANLAEG.svg"
import SevaerdighedFloraSVG from "./pictograms/points/SEVÆRDIGHED FLORA.svg";
import SejlsportRoklubSVG from "./pictograms/points/SEJLSPORT-ROKLUB.svg";
import SlaebestedSVG from "./pictograms/points/SLAEBESTED.svg";

import TeaterSVG from "./pictograms/points/TEATER.svg";
import TraekfaergeSVG from "./pictograms/points/TRAEKFÆRGE.svg";

import VirtueltOplevelsespunktSVG from "./pictograms/points/VIRTUELT OPLEVELSESPUNKT.svg";
import VandlegepladsSVG from "./pictograms/points/VANDLEGEPLADS.svg";

/* ===== ===== ==== Lines */
import SkiruteSVG from "./pictograms/lines/SKIRUTE.svg";
import MountainbikeruteSVG from "./pictograms/lines/MOUNTAINBIKE_RUTE.svg";
import RideruteSVG from "./pictograms/lines/RIDERUTE.svg";
import CykelruteSVG from "./pictograms/lines/CYKELRUTE.svg";
import VandrerouteSVG from "./pictograms/lines/VANDRERUTE.svg"
import SejlrouteSVG from "./pictograms/lines/SEJLRUTE.svg"

import FallbackSVG from "./pictograms/points/FALLBACK.svg";

import {Fill, Icon, Stroke, Style} from "ol/style";

declare global {
    type Dictionary<T> = { [key: string]: T };
}

// TODO: Rename to feature type
export enum EGeoType {
    Point = 't_5800_fac_pkt',
    Line = 't_5802_fac_li',
    Polygon = 't_5801_fac_fl'
}

// The field we filter on
// Choose from beliggenhedskommune eller kommune
export enum EFilterName {
    beliggenhedskommune,
    kommune
}

export interface IBaseTheme {
    pictogram: any
    label: string
    label_de?: string
    label_gb?: string
    description?: string
    color?: string
    pictogramColor?: string
}

export interface ILineTheme extends IBaseTheme {
    color: string;
    lineDash?: number[]
    begrebsdefinition?: string
}

export interface IPointTheme extends IBaseTheme {
}

export interface IPolygonTheme extends IBaseTheme {

}

interface IPointStore {
    [key: number]: {
        [key: string]: Style;
    };
}

export class IconService {

    pointStyleStore: IPointStore = {};

    FACILITY_LINE: Dictionary<ILineTheme> = {
        1: {
            pictogram: SkiruteSVG,
            label: 'Skirute',
            color: 'rgba(20,20,20, 1.0)',
            lineDash: [3, 5]
        },
        2: {
            pictogram: FallbackSVG,
            label: 'Løberute',
            color: 'rgba(20,20,20, 1.0)',
            lineDash: [3, 5]
        },
        3: {
            pictogram: CykelruteSVG,
            label: 'Cykelrute',
            label_de: 'Radstrecke',
            label_gb: 'Cycling route',
            color: 'rgba(20,20,20, 1.0)',
            lineDash: [3, 5]
        },
        4: {
            pictogram: MountainbikeruteSVG,
            label: 'Mountainbikerute',
            label_de: 'Mountainbikestrecke',
            label_gb: 'Mountain bike route',
            color: 'rgba(20,20,20, 1.0)',
            lineDash: [3, 5]
        },
        5: {
            pictogram: VandrerouteSVG,
            label: 'Vandrerute',
            label_de: 'Wanderpfad',
            label_gb: 'Hiking route',
            color: 'rgba(20,20,20, 1.0)',
            lineDash: [3, 5]
        },
        6: {
            pictogram: FallbackSVG,
            label: 'Motionsrute',
            label_de: 'Fitnessroute',
            color: 'rgba(20,20,20, 1.0)',
            lineDash: [3, 5]
        },
        7: {
            pictogram: FallbackSVG,
            label: 'Undervandsrute',
            color: 'rgba(20,20,20, 1.0)',
            lineDash: [3, 5]
        },
        8: {
            pictogram: RideruteSVG,
            label: 'Riderute',
            label_de: 'Reitweg',
            label_gb: 'Horse riding route',
            color: 'rgba(20,20,20, 1.0)',
            lineDash: [3, 5]
        },
        9: {
            pictogram: SejlrouteSVG,
            label: 'Kano- og Kajakrute',
            label_de: 'Kanu- und Kajakroute',
            label_gb: 'Canoe and Kayak Route',
            color: 'rgba(28, 0, 255, 1.0)',
            lineDash: [3, 5]
        },
        98: {
            pictogram: FallbackSVG,
            label: 'Andet',
            label_de: 'Andere Rute',
            begrebsdefinition: 'Hvor andre facilitetsbetegnelser ikke er dækkende.',
            color: 'rgba(28, 0, 255, 1.0)',
            lineDash: [3, 5]
        },
    }

    FACILITY_POLYGON: Dictionary<IPolygonTheme> = {
        3333: {
            pictogram: undefined,
            label: 'TODO'
        }
    }

    /*
    Only facilities with these numbers will be retrieved from the service
     */

    FACILITY_POINT: Dictionary<IPointTheme> = {
        1022: {
            pictogram: BaalpladsSVG,
            label: 'Bålplads',
            label_de: 'Feuerstelle',
            label_gb: 'Fire pit',
            pictogramColor: '#427c7f'
        },
        1031: {
            pictogram: LegepladsSVG,
            label: 'Legeplads',
            label_de: 'Spielplatz',
            label_gb: 'Playground',
            pictogramColor: '#427c7f'

        },
        1012: {
            pictogram: ToiletSVG,
            label: 'Toilet',
            label_de: 'Toilette',
            label_gb: 'Toilet',
            pictogramColor: '#ef6545'
        },
        1041: {
            pictogram: NaturlegepladsSVG,
            label: 'Naturlegeplads',
            label_de: 'Naturspielplatz',
            pictogramColor: '#427c7f'

        },
        1051: {
            pictogram: BadestrandStedSVG,
            label: 'Badestrand/-sted',
            label_de: 'Badestrand -platz',
            pictogramColor: ''
        },
        1061: {
            pictogram: BlaaFlagStrandSVG,
            label: 'Blå flag strand',
            label_de: 'Blaue Flagge Strand',
            label_gb: 'Blue flag beach'
        },
        1062: {
            pictogram: BadepunktSVG,
            label: 'Badepunkt',
            label_de: 'Badepunkt',
            description: 'Badested tildelt Det nordiske kystflag.'
        },
        1072: {
            pictogram: BlaaFlagFacilitetSVG,
            label: 'Blå flag facilitet',
            label_de: 'Blaue Flagge Facilität'
        },
        1082: {
            pictogram: SpejderhytteSVG,
            label: 'Spejderhytte',
            label_de: 'Pfadfinderhütte',
            description: ''
        },
        1091: {
            pictogram: FriluftsbadSvoemmebadSVG,
            label: 'Friluftsbad/Svømmebad',
            label_de: 'Freibad/Schwimmbad'
        },
        1102: {
            pictogram: SlaebestedSVG,
            label: 'Slæbested',
            label_de: 'Slipanlage',
            label_gb: 'Slipway',
            description: 'Anlæg til ophaling eller isætning af mindre både, evt. med spil.',
            pictogramColor: '#0072bc'
        },
        1182: {
            pictogram: TuristinformationSVG,
            label: 'Turistinformation',
            label_de: 'Touristeninformation',
            description: 'Kontor/lokaler, der har til formål at informere turister om lokalområdet.',
            pictogramColor: '#ef6545'

        },
        1191: {
            pictogram: HundeskovHundeparkSVG,
            label: 'Hundeskov/Friløbsareal',
            label_de: 'Hundewald, Freilaufareal',
            label_gb: 'Dog Forest, Off-lead Area'
        },
        1122: {
            pictogram: CykelpumpestationServicepostSVG,
            label: 'Cykelpumpestation/- servicepost',
            label_de: 'Fahrradpumpenstation/ -servicestelle',
            description: 'Sted hvor der er opstillet cykelpumpe og evt. andre faciliteter til service af cykler.',
            pictogramColor: '#427c7f'

        },
        1132: {
            pictogram: ToervejrsrumMadpakkehusSVG,
            label: 'Tørvejrsrum/Madpakkehus',
            label_de: 'Unterstand - Jausenraum',
            description: 'Halvåbent, overdækket sted, hvor du kan raste og spise medbragt mad.'
        },
        1142: {
            pictogram: VirtueltOplevelsespunktSVG,
            label: 'Virtuelt oplevelsespunkt',
            label_de: 'Virtueller Erlebnisort'
        },
        1152: {
            pictogram: InfoTavleSVG,
            label: 'Info-tavle',
            label_de: 'Infotafel',
            label_gb: 'Info board '
        },
        1201: {
            pictogram: BordBaenkePicnicSVG,
            label: 'Bord Bænk Picnic',
            label_de: 'Picknickplatz',
            label_gb: 'Picnic Table'
        },
        1211: {
            pictogram: VandlegepladsSVG,
            label: 'Vandlegeplads',
            label_de: 'Wasserspielplatz',
            label_gb: 'Water playground',
        },
        1222: {
            pictogram: VandpostSVG,
            label: 'Vandpost',
            label_de: 'Trinkwasser',
            label_gb: 'Water post'
        },
        1232: {
            pictogram: TraekfaergeSVG,
            label: 'Trækfærge',
            label_de: 'Seilzugfähre',
            description: 'En trækfærge er en pram som er forbundet med en wire. Trækfærgen forbinder bredderne på en ' +
                'å eller et vandløb så man kan med egen trækkraft komme over på den anden side.'
        },
        1291: {
            pictogram: HoldepladsSVG,
            label: 'Holdeplads',
            label_de: 'Parkplatz'
        },
        1302: {
            pictogram: FormidlingscenterSVG, // TODO: Formidlingscenter
            label: 'Natur- og kulturformidling',
            label_de: 'Natur- und Kulturvermittlung',
            label_gb: "Nature and Culture Information"
        },
        1331: {
            pictogram: BadebroSVG,
            label: 'Badebro',
            label_de: 'Badesteg',
            description: 'Bro med adgang til badning.',
        },
        1351: {
            pictogram: ParkeringspladsSVG,
            label: 'Parkerkinsplads',
            label_de: 'Parkplatz',
            label_gb: 'Parking space',
            description: 'Pplads.',
        },
        2012: {
            pictogram: FugletaarnSVG,
            label: 'Fugleskjul',
            label_de: 'Vogel-/Aussichtsturm',
            label_gb: 'Birdwatching tower',
            description: 'Platform, tårn eller skjul som giver mulighed for udsigt over et område eller særlige seværdigheder, fx udsyn til områdets fugle, dyreliv, landskab mv.'
        },
        2022: {
            pictogram: UdsigtspunktSVG,
            label: 'Udsigtspunkt',
            label_de: 'Aussichtspunkt',
            label_gb: 'Vantage Point',
            description: 'Punkt med en god udsigt. Ofte på toppen af en høj eller skrænt. Kan også være fra en bygning.'
        },
        2031: {
            pictogram: FiskesoeSVG,
            label: 'Fiskesø',
            label_de: 'Angelsee',
            label_gb: 'Fishing Lake'
        },
        2041: {
            pictogram: ReakreativtNaturomraadeSVG,
            label: 'Rekreativt naturområde',
            label_de: 'Freizeitnaturgebiet',
            label_gb: 'Recreational Nature Area'
        },
        2051: {
            pictogram: ParkSVG,
            label: 'Park',
            label_de: 'Park',
            label_gb: 'Park',
            description: 'Grønt byområde anlagt med en blanding af buske, træer, blomster og græs.'
        },
        2062: {
            pictogram: NaturcenterSVG,
            label: 'Naturcenter',
            label_de: 'Naturzentrum',
            label_gb: 'Nature Centre',
            description: 'Bygningsfacilitet som er udgangspunkt for offentlig naturformidling og aktiviteter i et naturområde. Oftest med ansat personale.',
            color: '#427c7f'

        },
        2072: {
            pictogram: NaturbaseSVG,
            label: 'Naturbase',
            label_de: 'Naturbasis',
            description: 'Plads i naturområde med mulighed for ophold, lån af grej, bålplads mm.'
        },
        2082: {
            pictogram: NaturskoleSVG,
            label: 'Naturskole',
            label_de: 'Naturschule',
            description: 'Bygningsfacilitet med primært formål at facilitere naturaktiviteter og undervisning for skoler og institutioner o.lign.'
        },
        2092: {
            pictogram: UdstillingSVG,
            label: 'Udstilling',
            label_de: 'Ausstellung'
        },
        2171: {
            pictogram: FritFiskeriSVG,
            label: 'Frit fiskeri',
            label_de: 'Freifischen'
        },
        2132: {
            pictogram: KaelkebakkeSVG,
            label: 'Kælkebakke',
            label_de: 'Rodelhügel',
            description: 'Område med mulighed for at kælke, når der er snedække.'
        },
        2201: {
            pictogram: SevaerdighedFloraSVG,
            label: 'Seværdighed – Flora',
            label_de: 'Sehenswürdigkeit - Flora',
            description: 'Seværdighed indenfor planteriget'
        },
        2223: {
            pictogram: NaturkanonSVG,
            label: 'Naturkanon',
            label_de: 'Naturkannon',
            label_gb: 'Naturecanon',
            description: 'Statens udpegede steder. Naturkanon steder/områder. Indmeldes kun af staten/MST.'
        },
        3012: {
            pictogram: ShelterSVG,
            label: 'Shelter',
            label_de: 'Unterschlupf',
            label_gb: 'Shelter'
        },
        3031: {
            pictogram: TeltpladsSVG,
            label: 'Teltplads',
            label_de: 'Zeltplatz',
            label_gb: 'Tent pitch'
        },
        3041: {
            pictogram: CampingpladsSVG,
            label: 'Campingplads',
            label_de: 'Campingplatz',
            label_gb: 'Camp site',
            pictogramColor: '#021e3a'
        },
        3051: {
            pictogram: HytterOgLejrskolerSVG,
            label: 'Hytter og Lejrskoler',
            label_de: 'Hütten und Schullandheim'
        },
        3061: {
            pictogram: FeriecenterSVG,
            label: 'Feriecenter',
            label_de: 'Ferienzentrum'
        },
        3071: {
            pictogram: FriTeltningSVG,
            label: 'Fri teltning',
            label_de: 'Freies Zelten',
            label_gb: 'Free camping'
        },
        3091: {
            pictogram: BaalhytteSVG,
            label: 'Bålhytte',
            label_de: 'Lagerfeuerhütte',
            label_gb: 'Fire put hut'
        },
        4011: {
            pictogram: IdraetshalSVG,
            label: 'Idrætshal',
            label_de: 'Turnhalle'
        },
        4021: {
            pictogram: SvoemmehalSVG,
            label: 'Svømmehal',
            label_de: 'Bad'
        },
        4041: {
            pictogram: CykelbaneSVG,
            label: 'Cykelbane',
            label_de: 'Radrennbahn'
        },
        4051: {
            pictogram: AgilitybaneSVG,
            label: 'Agilitybane',
            label_gb: 'Agility Course',
            label_de: 'Agility Parcours',
            description: 'Agilitybane er en hundesportsbane, hvor hundene skal tage en række forhindringer korrekt og i den rette rækkefølge.'
        },
        4081: {
            pictogram: MinigolfbaneSVG,
            label: 'Minigolfbane',
            label_de: 'Minigolfbahn',
            description: 'Anlæg til at spille minigolf på, hvor banelængden normalt er under 10 m.',
            color: "427c7f"
        },
        4101: {
            pictogram: Discgolfanlaeg,
            label: 'Discgolfanlæg',
            label_de: 'Disc Golf',
            description: 'Område med udpegede fairways og opstillede ”kurve” til spillet frisbee-golf/discgolf.',
            color: "427c7f"
        },
        4111: {
            pictogram: BoldbaneSVG,
            label: 'Boldbane',
            label_de: 'Fußballplatz',
            label_gb: 'Football pitch'
        },
        4121: {
            pictogram: SkydebaneanlaegSVG,
            label: 'Skydebaneanlæg',
            label_de: 'Schießstand'
        },
        4132: {
            pictogram: PentanquebaneSVG,
            label: 'Pétanquebane',
            label_de: 'Pétanque',
            description: 'En eller flere baner til petanque.'
        },
        4142: {
            pictogram: SkateanlaegSVG,
            label: 'Skateranlæg',
            label_de: 'Skaterpark'
        },
        4222: {
            pictogram: UdendoersFitnessMotionspladsSVG,
            label: 'Udendørs Fitness/Motionsplads',
            label_de: 'Fitnessplatz im Freien',
            label_gb: 'Outdoor fitness area'
        },
        4231: {
            pictogram: FlugtskydningsbaneSVG,
            label: 'Flugtskydningsbane',
            label_de: 'Tontaubenschiessen'
        },
        4322: {
            pictogram: OrienteringsruteStandpunktSVG,
            label: 'Orienteringsroute Standpunkt',
            label_de: 'Orientierungslaufsroute'
        },
        4251: {
            pictogram: KetchersportsanlaegSVG,
            label: 'Ketchersportsanlæg',
            label_de: 'Ketschersportanlage'
        },
        4272: {
            pictogram: KlatreanlaegSVG,
            label: 'Klatreanlæg',
            label_de: 'Kletteranlage',
            label_gb: 'Climbind Facility',
            pictogramColor: '#427c7f',
            description: 'Område med opsatte klatreudfordringer eller forhindringsbane f.eks. i træer, klatrevægge, wireforløb mm.'
        },
        4031: {
            pictogram: IdraetsanlaegSVG,
            label: 'Idrætsanlæg',
            label_de: 'Sportanlage',
            label_gb: 'Sports facilities',
            pictogramColor: '#427c7f'
        },
        4071: {
            pictogram: SkojtebaneSVG,
            label: 'Skøjtebane',
            label_de: 'Schlittschuhbahn',
            description: 'Bane til skøjteløb om vinteren på søer eller kunstige isbaner i byer både indendørs og udendørs.'
        },
        4161: {
            pictogram: RideanlaegSVG,
            label: 'Rideanlæg'
        },
        4191: {
            pictogram: KitesurfOmraadeSVG,
            label: 'Kitesurf område',
            label_de: 'Kitesurfingbereich',
            label_gb: 'Kitesurfing area'
        },
        4411: {
            pictogram: FiskeplatformSVG,
            label: 'Fiskeplatform',
            label_de: 'Angelplattform',
            label_gb: 'Fishing platform'
        },
        5011: {
            pictogram: ForsamlingshusSVG,
            label: 'Forsamlingshus',
            description: 'Et forsamlingshus er byens kulturhus og mødested.'
        },
        5021: {
            pictogram: MuseumSVG,
            label: 'Museum',
            label_de: 'Museum',
            label_gb: 'Museum'
        },
        5031: {
            pictogram: TeaterSVG,
            label: 'Teater',
            label_de: 'Theater',
            label_gb: 'Theatre',
            pictogramColor: '#021e3a',
            description: 'Sted, hvor der opføres teater eller andre kulturelle opførelser. Herunder også amfiteater.'
        },
        5051: {
            pictogram: BiografSVG,
            label: 'Biograf',
            label_de: 'Kino',
            label_gb: 'Movie Theatre',
            pictogramColor: '#021e3a',
            description: 'En biograf er et sted, sædvanligvis en bygning, hvor man fremviser film.'
        },
        5061: {
            pictogram: ForeningshusSVG,
            label: 'Foreningshus',
            label_de: 'Vereinshaus',
            pictogramColor: '#021e3a'
        },
        5072: {
            pictogram: FormidlingAfFortidsminderSVG,
            label: 'Fortidsminde',
            label_de: 'Archäologisches Erbe',
            label_gb: 'Ancient monument',
            pictogramColor: '#746d51'
        },
        5082: {
            pictogram: HistoriskeStederSVG,
            label: 'Historisk sted',
            label_de: 'Historischer Ort'
        },
        5102: {
            pictogram: KunstSVG,
            label: 'Kunst',
            label_de: 'Kunst',
            label_gb: 'Art',
            pictogramColor: '#746d51'
        },
        6011: {
            pictogram: BaadpladsSVG,
            label: 'Bådplads',
            label_de: 'Anlegestelle',
            label_gb: 'Mooring',
            color: '#0072bc',
            description: 'Fortøjningssted til både/joller.'
        },
        6052: {
            pictogram: KanoKajakrastepladsSVG,
            label: 'Kano-/kajakrasteplads',
            label_de: 'Kanu/Kajakpausenplatz',
            label_gb: 'Canoe/kayak lay-by'
        },
        6062: {
            pictogram: OphalerIsaetningspladsSVG,
            label: 'Ophaler-/isætningsplads',
            label_de: 'Startplatz',
            label_gb: 'Launch spot'
        },
        6042: {
            pictogram: SejlsportRoklubSVG,
            label: 'Sejlsports- og roklub',
            label_de: 'Segelsport- und Ruderklub',
            description: 'Klubhus, hvorfra der udøves sejlsport.'
        },
        9981: {
            pictogram: AndetSVG,
            label: 'Andet'
        }

    }

    public getTheme(themeNr: number): IBaseTheme | undefined {

        let gT = this.getGeotype(themeNr);
        let x;

        if (gT === EGeoType.Point) {
            x = this.FACILITY_POINT[themeNr];
        } else if (gT === EGeoType.Line) {
            x = this.FACILITY_LINE[themeNr];
        } else if (gT === EGeoType.Polygon) {
            x = this.FACILITY_POLYGON[themeNr]
        }
        return x;
    }

    /**
     * Get the theme type given its number
     * @param themeNr
     */
    public getGeotype(themeNr: number): EGeoType {

        let t: EGeoType;

        const pointThemes = Object.keys(this.FACILITY_POINT)
        const lineThemes = Object.keys(this.FACILITY_LINE)
        // let polygonThemes = Object.keys(this.FACILITY_POLYGON);

        // TODO: Change this.FACILITY_* returns number keys
        if (pointThemes.includes(String(themeNr))) {
            t = EGeoType.Point
        } else if (lineThemes.includes(String(themeNr))) {
            t = EGeoType.Line
        } else {
            t = EGeoType.Polygon
        }
        return t;
    }

    public getSvgColor(geoType: EGeoType, id: string): any {
        let x;

        if (geoType === EGeoType.Point) {
            x = this.FACILITY_POINT[id]?.pictogramColor;
        }

        if (geoType === EGeoType.Line) {
            x = this.FACILITY_LINE[id]?.pictogramColor;
        }

        if (geoType === EGeoType.Polygon) {
            x = this.FACILITY_POLYGON[id]?.pictogramColor;
        }

        return x;
    }

    public getSvgSrc(geoType: EGeoType, id: string): any {

        let x;

        if (geoType === EGeoType.Point) {
            x = this.FACILITY_POINT[id]?.pictogram;
        }

        if (geoType === EGeoType.Line) {
            x = this.FACILITY_LINE[id]?.pictogram;
        }

        if (geoType === EGeoType.Polygon) {
            x = this.FACILITY_POLYGON[id]?.pictogram;
        }

        if (!x) {
            console.warn(`PICTOGRAM FALLBACK: ${geoType}: id=${id} `);
            x = FallbackSVG;
        }
        return x;
    }

    public __getOpenLayersPointStyle(id: string, width?: number, zIndex?: number) {

        const zIx = zIndex || -1;

        // Let's build a fallback for the themes that are not defined here
        let x = this.FACILITY_POINT[id]?.pictogram;
        if (!x) {
            console.warn('Fallback: (FACILITY_POINT): ', id)
            x = FallbackSVG;
        }

        return new Style({
            image: new Icon({
                src: x,
                width: width || 30
            }),
            zIndex: zIx
        });

    }

    public getPointStyleFromStore(id: string, width: number): Style | undefined {

        if (width in this.pointStyleStore!) {
            return this.pointStyleStore![width][id];
        } else {
            return undefined
        }
    }

    public setPointStyleInStore(id: string, width: number, style: Style) {

        if (width in this.pointStyleStore) {
            this.pointStyleStore[width][id] = style
        } else {
            this.pointStyleStore[width] = {}
            this.pointStyleStore[width][id] = style
        }
    }

    public getOpenLayersPointStyle(id: string, width?: number, zIndex?: number) {

        const zIx = zIndex || -1;

        // Let's build a fallback for the themes that are not defined here
        let x = this.FACILITY_POINT[id]?.pictogram;
        if (!x) {
            console.warn('Fallback: (FACILITY_POINT): ', id)
            x = FallbackSVG;
        }

        let style = this.getPointStyleFromStore(id, width || 30)

        if (style) {
        } else {
            style = new Style({
                image: new Icon({
                    src: x,
                    width: width || 30
                }),
                zIndex: zIx
            });
            this.setPointStyleInStore(id, width || 30, style)
        }

        // TODO: put box shadow here

        return style;

    }


    public getOpenLayersLineStyle(id: string, width: number): Style {

        let color = this.FACILITY_LINE[id]?.color;
        let lineDash = this.FACILITY_LINE[id]?.lineDash;

        // Fallback
        if (!color) {
            console.warn('Route style not defined for: ', id)
            color = '#1234';
        }

        // Fallback
        if (!lineDash) {
            lineDash = [1, 1]
        }

        return new Style({
            stroke: new Stroke({
                width: width,
                color: color,
                lineDash: lineDash,
            }),
        });
    }

    /*
    TODO: Finalize when we have polygon data.
     */
    public getOpenLayersPolygonStyle(id: string, width: number): Style {
        return new Style({
            fill: new Fill(
                {
                    color: [255, 255, 255, 0.5],
                }
            )
        });
    }
}
