import {IonApp, setupIonicReact} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import MapContextProvider from "./contexts/MapContextProvider";

import * as React from "react";

import './App.css';
import AppRouterWrapper from "./components/AppRouterWrapper/AppRouterWrapper";
import {useEffect} from "react";

setupIonicReact();

const App: React.FC = () => {

    useEffect(() => {
        document.title = "Tønder Friluftsapp";
    }, []);

    return (
        <div className="phone-frame">
            <IonApp>

                <MapContextProvider>
                    <AppRouterWrapper></AppRouterWrapper>
                </MapContextProvider>

            </IonApp>
        </div>
    );
}

export default App;
