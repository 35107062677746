import * as React from "react";
import {
    IonButton, IonCol,
    IonContent, IonGrid, IonIcon,
    IonPage, IonRow,
} from "@ionic/react";
import { Clipboard, ReadResult } from '@capacitor/clipboard';

import styles from './MunicipalityListPage.module.css';
import {EXTENDEDMUNICIPALITIES} from "../../services/kommuner/ExtendedMunicpalities";

const URL_BASE: string = 'https://friluftskort.nordiq-gis.dk/kommune/';

function openLinkInNewTab(url: string) {
    window.open(url, '_blank');
}

import {checkboxOutline, shareOutline} from 'ionicons/icons';

const MunicpalityListPage: React.FC = () => {

    const [checked, setChecked] = React.useState<string[]>([]);

    const extendedMunicipalitiesList = Object.keys(EXTENDEDMUNICIPALITIES)
        .map((guid) => ({
            guid,
            kommunenr: EXTENDEDMUNICIPALITIES[guid].kommunenr,
            navn: EXTENDEDMUNICIPALITIES[guid].navn
        }))
        .sort((a, b) => a.navn.localeCompare(b.navn));

    console.log(extendedMunicipalitiesList);


    function click(muni: any): void {
        console.log()
        openLinkInNewTab(URL_BASE + muni.guid);
    }

    async function copyToCliboard(muni: any) {
        console.log(muni);
        await Clipboard.write({
            string: URL_BASE + muni.guid
        });
        if (!checked.includes(muni.guid)) {
            let x = checked;
            x.push(muni.guid)
            setChecked(x)
            console.log(checked);
        }
    }

    function checkClip() {
        Clipboard.read().then((clipboardRead: ReadResult) => {
            if (clipboardRead?.value) {
                console.log(clipboardRead.value)
                // Call any functions, method, do whatever you need with the input
                // call event.preventDefault() if you need to do anything with the captured string instead of pasting it directly into the input
                return;
            }
        });
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    {extendedMunicipalitiesList.map(muni => (
                        <IonRow>
                            <IonCol size="auto">
                                <IonButton color={'warning'} key={muni.guid} onClick={(x) => click(muni)}>
                                    {muni.navn}
                                </IonButton>
                            </IonCol>

                            <IonCol>
                                {/*
                                {muni.guid} {checked.includes(muni.guid) ? <b>xxx</b>: <i>abc</i>}
                                {!checked.includes(muni.guid) ?
                                    <>x*/}
                                        <IonButton color={'medium'} onClick={() => copyToCliboard(muni)}><IonIcon size="small" icon={shareOutline} /></IonButton>
                                {/*
                            </>
                                    : <>
                                    y
                                        <IonButton color={'medium'} onClick={() => copyToCliboard(muni)}><IonIcon size="small" icon={checkboxOutline} /></IonButton>
                                    </>
                                }
                                {/* <a href={URL_BASE + muni.guid}>{URL_BASE + muni.guid}</a> */}
                                {/*<IonButton onClick={() => checkClip()}></IonButton>*/}
                            </IonCol>
                        </IonRow>
                    ))}
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default MunicpalityListPage;
