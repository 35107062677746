import * as React from "react";

import nordiq from "./nordiq.svg";
import toender from "./toender.svg";
import geodanmark from "./geodanmark.svg";

import {useContext, useRef, useState} from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon, IonImg,
    IonModal,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonToolbar
} from "@ionic/react";
import {Route, useParams} from "react-router-dom";
import MapTab from "../../pages/MapTab";
import WidePage from "../../pages/Widepage/WidePage";
import {arrowDownOutline, closeOutline, listOutline, planetOutline} from "ionicons/icons";
import MapContext from "../../contexts/MapContext";
import ListLabel from "../../ListLabel";
import ListModalContent from "../../ListModalContent";

import FilterListComponent from "../Filter/FilterListComponent/FilterListComponent";
import {AppConfig} from "../../utils/AppConfig";

import styles from "./AppRouterWrapper.module.css";
import FilterIconWithLabel from "../Filter/FilterIconWithLabel/FilterIconWithLabel";
import MunicipalitiesModalContent from "../../MunicipalitiesModalContent";
import {useTranslation} from "react-i18next";
import MunicpalityListPage from "../../pages/MunicipalityListPage/MunicipalityListPage";
import AboutIconWithLabel from "../About/AboutIconWithLabel";
import {IonReactRouter} from "@ionic/react-router";

/*

The AppRouterWrapper contains the Tab bars (right now List and Filter) in the bottom and the corresponding modals that slide up.
The Routes are here too.

 */
const AppRouterWrapper: React.FC = () => {

    const {getState} = useContext(MapContext);

    const listModal = useRef<HTMLIonModalElement>(null);
    const filterModal = useRef<HTMLIonModalElement>(null);
    const municipalitiesModal = useRef<HTMLIonModalElement>(null);

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showAboutModal, setShowAboutModal] = useState(false);
    const [showMunicipalitiesModal, setShowMunicipalitiesModal] = useState(false);

    const { t } = useTranslation();


    // const domainName = window.location.hostname;

    function handleOpenListModal() {
        getState().setShowListModal(true);
    }

    function onDismiss() {
        getState().setTappedFeatures([]);
        getState().setFeatureIdInMapOverlay(undefined);
        getState().setSelectedObjectId(undefined)
        setShowFilterModal(false)
    }

    function handleListModalDismiss() {
        getState().setTappedFeatures([]);
        getState().setFeatureIdInMapOverlay(undefined);
        getState().setSelectedObjectId(undefined);
        getState().setShowListModal(false);
        getState().setListModalState('list')
    }

    function getCloseLabel() {
        return t('close');
        /*
        const lang = getState().language;
        if (lang === 'da') {
            return 'LUK'
        } else if (lang === 'de') {
            return 'SCHLIESSEN'
        } else {
            return "CLOSE"
        }
         */
    }

    function getResetLabel() {
        return t("resetFilter");
    }

    function handleOpenFilterModal() {
        setShowFilterModal(true);
    }

    function handleOpenAboutModal() {
        setShowAboutModal(true);
    }

    function handleCloseAboutModal() {
        setShowAboutModal(false);
    }

    function handleCloseFilterModal() {
        setShowFilterModal(false);
    }

    function handleOpenMunicipalitiesModal() {
        getState().setShowMunicipalitiesModal(true);
    }

    function resetFilter() {
        getState().setThemeFilter([]);
        setShowFilterModal(false);
    }

    function buttonsDisabled(): boolean {
        return getState().allFeatures.length === 0
    }

    return (
        <IonReactRouter>
            <IonTabs>
                <IonRouterOutlet>
                    <Route exact path="/" component={MapTab}></Route>
                    <Route exact path="/kommune/:kommuneGUID" component={MapTab}></Route>
                    <Route exact path="/paddle-1234-tennis-5678" component={MunicpalityListPage}></Route>
                    <Route exact path="/facility/:objectId" component={MapTab}></Route>
                    <Route exact path="/wide" component={WidePage}></Route>
                </IonRouterOutlet>

                <IonTabBar slot="bottom" className={styles.customTabBar}>
                    <IonTabButton tab="about"
                                  className={styles.IonTabButton}
                                  onClick={() => handleOpenAboutModal()}>
                        <AboutIconWithLabel></AboutIconWithLabel>
                        <IonModal id="about-modal"
                                  isOpen={showAboutModal}>
                            <IonHeader className={styles.header}>
                                <IonToolbar className={styles.toolbar}>
                                    <IonButtons slot="end">
                                        <IonButton onClick={handleCloseAboutModal}>
                                            <IonIcon icon={arrowDownOutline}></IonIcon>
                                            {getCloseLabel()}
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent className="ion-padding">
                                {/* <div>{domainName}</div> */}
                                <div dangerouslySetInnerHTML={{__html: t('aboutContent')}}></div>
                                <div className={styles.LogoFlex}>
                                    <div>
                                        <IonImg src={toender}
                                                alt="Tønder kommune"
                                                className={styles.Nordiq}>
                                        </IonImg>
                                    </div>
                                    <div>
                                        <IonImg src={nordiq}
                                                alt="Nordiq Group"
                                                className={styles.Nordiq}>
                                        </IonImg>
                                    </div>
                                    <div>
                                        <IonImg src={geodanmark}
                                                alt="Geodanmark"
                                                className={styles.Nordiq}>
                                        </IonImg>
                                    </div>
                                </div>

                            </IonContent>
                        </IonModal>
                    </IonTabButton>
                    <IonTabButton tab="filter"
                                  className={styles.IonTabButton}
                                  onClick={() => handleOpenFilterModal()}
                                  disabled={buttonsDisabled()}>
                        <FilterIconWithLabel></FilterIconWithLabel>
                        <IonModal id="filter-modal"
                                  keepContentsMounted={false}
                                  isOpen={showFilterModal}
                                  onDidDismiss={onDismiss}
                                  ref={filterModal}
                                  backdropDismiss={false}
                                  initialBreakpoint={AppConfig.modals.filter.initialBreakPoint}
                                  breakpoints={AppConfig.modals.filter.breakpoints}>

                            <IonHeader className={styles.header}>
                                <IonToolbar className={styles.toolbar}>
                                    <IonButtons slot="end">
                                        <IonButton onClick={handleCloseFilterModal}>
                                            <IonIcon icon={arrowDownOutline}></IonIcon>
                                            {getCloseLabel()}
                                        </IonButton>
                                    </IonButtons>
                                    <IonButtons slot="start">
                                        <IonButton onClick={resetFilter} disabled={getState().themeFilter.length === 0}>
                                            <IonIcon icon={closeOutline}></IonIcon>
                                            {getResetLabel()}
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <FilterListComponent></FilterListComponent>
                            </IonContent>
                        </IonModal>
                    </IonTabButton>

                    {/* The modal for list and detail view */}
                    <IonTabButton tab="liste"
                                  className={styles.IonTabButton}
                                  onClick={() => handleOpenListModal()}
                                  disabled={buttonsDisabled()}>
                        <IonIcon aria-hidden="true" icon={listOutline}/>
                        <ListLabel></ListLabel>
                        <IonModal id="list-modal"
                                  ref={listModal}
                                  isOpen={getState().showListModal}
                                  keepContentsMounted={true}
                                  onDidDismiss={handleListModalDismiss}
                                  handleBehavior="cycle"
                                  className={styles.ionModal}
                                  initialBreakpoint={AppConfig.modals.list.initialBreakPoint}
                                  backdropDismiss={false}
                                  showBackdrop={true}
                                  breakpoints={AppConfig.modals.list.breakpoints}>
                            <IonContent className={styles.initialListModalHeight}>
                                <ListModalContent></ListModalContent>
                            </IonContent>
                        </IonModal>
                    </IonTabButton>
                </IonTabBar>

            </IonTabs>

        </IonReactRouter>
    )
}

export default AppRouterWrapper;
